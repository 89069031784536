import { Component, Input } from '@angular/core';

import { CoreMenuItem } from '@core/types';
import { GeneralService } from 'app/auth/service';

@Component({
  selector: '[core-menu-vertical-item]',
  templateUrl: './item.component.html'
})
export class CoreMenuVerticalItemComponent {
  @Input()
  item: CoreMenuItem;

  constructor(private _general:GeneralService){

  }

  action(key){
    this._general.action.next(key)
  }
}
