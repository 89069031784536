<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i
    >
    <span *ngIf="notifications?.unread_notifications" class="badge badge-pill badge-danger badge-up">{{notifications?.unread_notifications}}</span>
    </a
  >
  <ul
    ngbDropdownMenu style="width:400px"
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto natification-text">{{'announcement'|translate}}</h4>
        <div class="badge badge-pill badge-light-primary">新着{{ notifications?.unread_notifications }}件</div>
      </div>
    </li>
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <a class="d-flex" href="javascript:void(0)" *ngFor="let message of notifications?.data" (click)="DetailPage(message)">
        <div class="media d-flex align-items-start">
          <!-- <div class="media-left">
            <div *ngIf="message?.photoList[0]?.url" class="avatar">
              <img [src]="message?.photoList[0]?.url" alt="avatar" width="32" height="32" />
            </div>
            <div *ngIf="!message?.photoList[0]?.url" class="announcement-profile" width="32" height="32"></div>
          </div> -->
          <div class="media-body">
            <p class="media-heading notification-subject">{{message?.message}}</p>
            <small class="notification-text" >{{message?.created | date: 'MM/dd HH:mm'}}</small>
          </div>
        </div></a
      >
      <!-- <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">System Notifications</h6>
        <div class="custom-control custom-control-primary custom-switch">
          <input
            class="custom-control-input"
            id="systemNotification"
            type="checkbox"
            [checked]="notifications.system"
          />
          <label class="custom-control-label" for="systemNotification"></label>
        </div>
      </div> -->
      <!-- <a class="d-flex" href="javascript:void(0)" *ngFor="let systemMessage of notifications.systemMessages">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div
              class="avatar"
              [ngClass]="{
                'bg-light-danger': systemMessage.icon === 'x',
                'bg-light-success': systemMessage.icon === 'check',
                'bg-light-warning': systemMessage.icon === 'alert-triangle'
              }"
            >
              <div class="avatar-content"><i class="avatar-icon" [data-feather]="systemMessage.icon"></i></div>
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading" [innerHTML]="systemMessage.heading"></p>
            <small class="notification-text">{{ systemMessage.text }}</small>
          </div>
        </div></a
      > -->
    </li>
    <!-- <li class="dropdown-menu-footer">
      <a class="btn btn-primary btn-block" href="javascript:void(0)">Read all notifications</a>
    </li> -->
  </ul>
</li>
