import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule,TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { ContextMenuModule } from '@ctrl/ngx-rightclick';

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { coreConfig } from 'app/app-config';
import { AuthGuard } from 'app/auth/helpers/auth.guards'; // used to create fake backend
import { JwtInterceptor} from './auth/helpers/jwt.interceptor';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { SharedModule } from './shared/shared.module';
import { environment } from 'environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { GeneralService } from './auth/service/general.service';
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  {
    path:'auth',
    loadChildren: () => import('./authorization/authorization.module').then(m => m.AuthorizationModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule)
  },
  {
    path: 'apps',
    loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'documents',
    loadChildren: () => import('./document-list/document-list.module').then(m => m.DocumentListModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'account-management',
    loadChildren: () => import('./account-management/account-management.module').then(m => m.AccountManagementModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'recruitment-management',
    loadChildren: () => import('./recruitment-management/recruitment-management.module').then(m => m.RecruitmentManagementModule),
    // canActivate: [AuthGuard]
  },{
    path: 'interview-result-report-list',
    loadChildren:()=>import('./interview-result-list/interview-result-list.module').then(m=>m.InterviewResultListModule),
    // canActivate: [AuthGuard]
  },
  {
    path:"invoice-management",
    loadChildren:()=>import('./invoice-management/invoice-management.module').then(m=>m.InvoiceManagementModule)
  },
  {
    path:"penalty-management",
    loadChildren:()=>import('./penalty-management/penalty-management.module').then(m=>m.PenaltyManagementModule)
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// export function createTranslateLoader(http: HttpClient) {
//   return new TranslateHttpLoader(http, "../assets/i18n/", ".json");
// }
let language=localStorage.getItem("Language")
Bugsnag.start({ apiKey: environment.bugsnag });
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    // HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
    //   delay: 0,
    //   passThruUnknownUrl: true
    // }),
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    NgbModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ContextMenuModule,
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    CardSnippetModule,
    LayoutModule,
    ContentHeaderModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    // TranslateModule.forRoot({
    //   defaultLanguage: language?language:"ja",
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: createTranslateLoader,
    //     deps: [HttpClient]
    //   }
    // })
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // ! IMPORTANT: Provider used to create fake backend, comment while using real API
    // fakeBackendProvider
  ],
  // entryComponents: [BasicCustomContextMenuComponent, AnimatedCustomContextMenuComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}