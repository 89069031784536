<ng-container *ngIf="!item.hidden">
  <!-- item with router url -->
  <a
    class="d-flex align-items-center"
    [ngClass]="item.classes"
    *ngIf="item.url && !item.externalUrl"
    [routerLink]="[item.url]"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item with external url -->
  <a
    class="d-flex align-items-center"
    [ngClass]="item.classes"
    *ngIf="item.url && item.externalUrl"
    [href]="item.url"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item without url -->
  <p
  class="dropdown-item d-flex align-items-center"
  [ngClass]="item.classes"
  *ngIf="!item.url && !item.externalUrl"
  (click)="action(item?.id)"
>
  <ng-container *ngTemplateOutlet="itemContent"></ng-container>
 </p>

  <ng-template #itemContent>
    <span [data-feather]="item.icon" *ngIf="item.icon"></span>
    <img *ngIf="!item?.icon && item?.custom_icon?.inactive" [src]="item?.custom_icon?.inactive" class="custom-icon-inactive" alt="">
    <img *ngIf="!item?.icon && item?.custom_icon?.active" [src]="item?.custom_icon?.active" class="custom-icon-active" alt="">
    <span class="menu-title text-truncate" [translate]="item.title">{{ item.title|translate }}</span>
    <span
      class="badge ml-auto mr-1"
      *ngIf="item.badge"
      [translate]="item.badge.translate"
      [ngClass]="item.badge.classes"
    >
      {{ item.badge.title }}
    </span>
  </ng-template>
</ng-container>
