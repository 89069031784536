<div class="container">
    <div class="row py-3">
        <div class="col-md-12 mt-2">
            <div class="title text-center">
              {{titleText}}<br>{{titleText2}}
            </div>
            <div class="label pt-1 text-center">
                {{subTitleText}}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 text-center py-2">
            <button (click)="onCancel()" type="button" class="btn btn-light">{{"cancel"|translate}}</button>
            <button (click)="onConfirm()" type="button" class="btn btn-dark ml-2">{{"application_withdraw"|translate}}</button>
        </div>
    </div>
</div>