import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChartjsModule } from 'app/main/charts-and-maps/charts/chartjs/chartjs.module';

@NgModule({
  declarations: [],
  imports: [CommonModule,ChartjsModule]
})
export class ChartModule {}
