import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  currentLanguage = new BehaviorSubject("ja");
  public currentAction: Observable<any>;
  action: BehaviorSubject<any>;
  constructor( 
    private router:Router,
    private apiService: ApiService,
    public translate: TranslateService,
    ) { 
      this.action = new BehaviorSubject<any>({id:'none'});
      this.currentAction = this.action.asObservable();
    }

    changeLang(selectedLanguage) {
      this.translate.use(selectedLanguage);
      localStorage.setItem("Language", selectedLanguage);
      this.setLanguage();
    }

    setLanguage() {
      const language = localStorage.getItem("Language");
      if (!language) {
        this.translate.setDefaultLang("ja");
        localStorage.setItem("Language", "ja");
        this.currentLanguage.next("ja");
      } else {
        this.translate.use(language);
        this.currentLanguage.next(language);
      }
    }

    getCurrentLanguage() {
      const language = localStorage.getItem("Language");
      if (!language) {
        this.translate.setDefaultLang("ja");
        localStorage.setItem("Language", "ja");
      } else {
        this.translate.use(language);
      }
  
      return this.translate.currentLang;
    }

    set setToken(token: any) {
      localStorage.setItem('kaigoToken', token);
    }
  
    get getToken() {
      if (localStorage.getItem('kaigoToken')) {
        return localStorage.getItem('kaigoToken');
      } else {
        return null;
      }
    }

    set setRefreshToken(token: any) {
      localStorage.setItem('refreshToken', token);
    }
  
    get getRefreshToken() {
      if (localStorage.getItem('refreshToken')) {
        return localStorage.getItem('refreshToken');
      } else {
        return null;
      }
    }

    set setuid(uid: any) {
      localStorage.setItem('uid', uid);
    }
  
    get getuid() {
      if (localStorage.getItem('uid')) {
        return localStorage.getItem('uid');
      } else {
        return null;
      }
    }

    changeAction(item){
      this.action.next(item)
    }

    logOut(role) {
      localStorage.clear();
    }
}
