import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
   titleText: string;
   titleText2: string;
   subTitleText:string;
  constructor(  
    public activeModal: NgbActiveModal) { 
  }

  ngOnInit(): void {
   
  }
  onCancel(): void{
    this.activeModal.close(false);
  }
  onConfirm(): void{
    this.activeModal.close(true);
  }

}
