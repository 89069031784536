import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'app/auth/service';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import { ApiConstant } from 'app/shared/api-constents';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Interface
interface notification {
  // messages: [];
  // systemMessages: [];
  // system: Boolean;
  count: Number
  data: []
  unread_notifications: Number
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: notification;
  private unsubscribe = new Subject<void>();
  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(
    private _notificationsService: NotificationsService,
    private apiService: ApiService,
    private router: Router) { }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._notificationsService.onApiDataChange.subscribe(res => {
      this.notifications = res;
    });
  }

  DetailPage(message) {
    let id = message?.createdBy
    let app_id = ""
    switch (message?.type) {
      case "cancel_application":
        this.router.navigateByUrl('/penalty-management/penalty-info-by-worker');
        break;
      case "user_job_applied":
        this.router.navigateByUrl('/recruitment-management/parttime-job-management');;
        break;
      case "application_confirmation_status":
        this.router.navigateByUrl('/recruitment-management/parttime-job-management');;
        break;
      case "reward_correction_approval":
        this.router.navigateByUrl('/recruitment-management/parttime-job-management');;
        break;
      case "REWARD_CORRECTION_REQUEST":
        this.router.navigateByUrl('/recruitment-management/parttime-job-management');
        break;
      case "Announcement":
        this.router.navigateByUrl('/settings/announcement-management');
        break;
      case "id_status":
        this.router.navigateByUrl(`/account-management/care-worker-profile/${id}/${app_id}`)
        break;
      case "upload_proof":
        this.router.navigateByUrl('/documents/document?backTo=identity_document')
        break;
        case "upload_proof_":
          this.router.navigateByUrl('/documents/document?backTo=qualification_document')
          break;
          case "interview_report":
          this.router.navigateByUrl('/interview-result-report-list/interview-result-report-list')
          break;
          case "worker_absentism_report":
            this.router.navigateByUrl('/penalty-management/absent-management/waiting-for-approval-absentee-report')
            break;
        
    }
    this.apiService
      .ExecutePut(`${this.apiService.baseUrl + ApiConstant.notificationViewed}/${message?._id}`, {})
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (response: any) => {
          console.log("res",response)
          this._notificationsService.getNotificationsData()
        });
  }
}
