import { Component, OnDestroy, OnInit, HostBinding, HostListener, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil,filter } from 'rxjs/operators';


import { AuthenticationService, GeneralService } from 'app/auth/service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreMediaService } from '@core/services/media.service';

import { User } from 'app/auth/models';

import { coreConfig } from 'app/app-config';
import { Router } from '@angular/router';
import { IUserInfo } from 'app/shared/general-interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { TranslateService } from 'app/shared/translate.service';
import { environment } from 'environments/environment';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {
  @ViewChild('logoutModal') logoutModal: ElementRef;
  public horizontalMenu: boolean;
  public hiddenMenu: boolean;
  public vertical:boolean=false
  env=environment
  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;

  public currentUser: IUserInfo;

  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;

  @HostBinding('class.fixed-top')
  public isFixed = false;

  @HostBinding('class.navbar-static-style-on-scroll')
  public windowScrolled = false;
  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == 'navbar-static-top' &&
      this.coreConfig.layout.type == 'horizontal'
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }
    
  // Private
  private _unsubscribeAll: Subject<any>;
  menu: any;
  lang =localStorage.getItem("selectedLanguage")||'ja'
  /**
   * Constructor
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreMediaService} _coreMediaService
   * @param {MediaObserver} _mediaObserver
   * @param {TranslateService} _translateService
   * @param {CoreMenuService} _coreMenuService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    public _translateService: TranslateService,
    private _general : GeneralService,
    private modalService :NgbModal,
    private _coreMenuService: CoreMenuService,
  ) {
    // _translateService.setDefaultLang('ja');
    this.changeLang(localStorage.getItem("selectedLanguage")?localStorage.getItem("selectedLanguage"):'ja')
    
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this._general.currentAction.subscribe((value)=>{
      if(value){
        if(value?.id==='logout'){
          this.logout(this.logoutModal)
        }
        
      }
    })
    this.languageOptions = {
      en: {
        title: 'English',
        flag: 'us'
      },
      fr: {
        title: 'French',
        flag: 'fr'
      },
      de: {
        title: 'German',
        flag: 'de'
      },
      pt: {
        title: 'Portuguese',
        flag: 'pt'
      }
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    console.log("key-------------------------",key)
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  /**
   * Set the language
   *
   * @param language
   */
  setLanguage(language): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language;

    // Use the selected language id for translations
    this._translateService.use(language);

    this._coreConfigService.setConfig({ app: { appLanguage: language } }, { emitEvent: true });
  }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.currentSkin = config.layout.skin;
      });

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem('prevSkin');

    if (this.currentSkin === 'dark') {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : 'default' } },
        { emitEvent: true }
      );
    } else {
      localStorage.setItem('prevSkin', this.currentSkin);
      this._coreConfigService.setConfig({ layout: { skin: 'dark' } }, { emitEvent: true });
    }
  }

  /**
   * Logout method
   */
 

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // get the currentUser details from localStorage
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(window.screen.availWidth<1200){
      this.vertical=true
    }else{
      this.vertical=false
    }
    // Subscribe to the config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      console.log("this.coreConfig.layout.navbar.customBackgroundColor",this.coreConfig.layout.navbar.customBackgroundColor)
      this.horizontalMenu = config.layout.type === 'horizontal';
      this.hiddenMenu = config.layout.menu.hidden === true;
      this.currentSkin = config.layout.skin;
      console.log("this.coreConfig",this.coreConfig)
      // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
      if (this.coreConfig.layout.type === 'vertical') {
        setTimeout(() => {
          if (this.coreConfig.layout.navbar.type === 'fixed-top') {
            this.isFixed = true;
          }
        }, 0);
      }
    });

    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == 'horizontal') {
      // On every media(screen) change
      this._coreMediaService.onMediaUpdate.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
        const isFixedTop = this._mediaObserver.isActive('bs-gt-xl');
        if (isFixedTop) {
          this.isFixed = false;
        } else {
          this.isFixed = true;
        }
      });
    }

    // Set the selected language from default languageOptions
    this.selectedLanguage = _.find(this.languageOptions, {
      // id: this._translateService.currentLang
    });

    // this._coreMenuService.onMenuChanged
    //   .pipe(
    //     filter(value => value !== null),
    //     takeUntil(this._unsubscribeAll)
    //   )
    //   .subscribe(() => {
    //     this.menu = this._coreMenuService.getCurrentMenu();
    //   });

  }

  onImgError(event){
    event.target.src = 'assets/images/kaigo-images/avatar.jpg'
   //Do other stuff with the event.target
   }

   changeLang(lang) {
    console.log("lang",lang)
    this.lang = lang;
    this._general.changeLang(lang);
    localStorage.setItem("selectedLanguage",lang)
    // window.location.reload();
    this._translateService.use(lang)
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  logout(logoutModal){
    //   const modalRef = this.modalService.open(logoutModal, {
    //    centered: true,
    //    size: 'sm',
    //    windowClass: 'modal modal-dark'
    //   });
    //  modalRef.result.then((data) => {
    //    if(data==='logout'){
    //     this._authenticationService.logout()
    //    }
    //    else{
    //     this._general.changeAction(null)
    //    }
    //  })
     this._authenticationService.logout()
   }
}
