<div *ngIf="horizontalMenu" id="hideShow" style="margin-left:20px;margin-right:20px;position: relative; ">
  <div>
    <img src="../../../../assets/icons/kaigo-logo.svg" alt="" style="width:30px;height:30px">
  </div>
  <div
    [ngClass]="[
    coreConfig.layout.navbar.customBackgroundColor === true
    ? coreConfig.layout.navbar.background +
      ' ' +
      coreConfig.layout.navbar.type +
      ' ' +
      coreConfig.layout.navbar.backgroundColor
    : coreConfig.layout.navbar.background + ' ' + coreConfig.layout.navbar.type,
    coreConfig.layout.navbar.type === 'floating-nav' ? 'container-xxl' : ''
    ]"
    class="header-navbar navbar-horizontal menu-border" style="background-color:transparent;width: 1050px;margin-left: 0px;padding-left: 50px;position: absolute;top: -35px;"
  >
    <ul class="nav navbar-nav" id="main-menu-navigation" layout="horizontal" core-menu></ul>
  </div>
</div>
<div class="navbar-container d-flex" >
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Bookmark -->
    <!-- <app-navbar-bookmark></app-navbar-bookmark> -->
    <!--/ Bookmark -->
  </div>
  <ul class="nav navbar-nav align-items-center ml-auto">
    <div *ngIf="!env.production">
      <span (click)="changeLang('en')" [ngClass]="{ 'translate-text-selected': lang == 'en' }"
        class="translate-text user-name">EN</span><span> / </span><span (click)="changeLang('ja')"
        [ngClass]="{ 'translate-text-selected': lang == 'ja' }" class="translate-text user-name">JP</span>
    </div>
    
    <app-navbar-notification></app-navbar-notification>
    <!--/ Notification -->
    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <ng-container *ngIf="this.currentUser">
          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder" style="padding-top: 5px;">{{ this.currentUser?.name }} </span>
            <span class="user-status">{{ this.currentUser?.role=="super_admin"?"オーナー管理者":"サブ管理者" }} </span>
          </div>
          <!-- <img
          class="round"
          [src]="this.currentUser?.photoList[0]?.url || 'assets/icons/avatar.jpg'"
          height="40"
          width="40" 
          (error)="onImgError($event)"/> -->
        </ng-container>
      </a>
      <!-- <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <a ngbDropdownItem [routerLink]="['/pages/profile']"
          ><span [data-feather]="'user'" [class]="'mr-50'"></span> Profile</a
        ><a ngbDropdownItem [routerLink]="['/apps/email']"
          ><span [data-feather]="'mail'" [class]="'mr-50'"></span> Inbox</a
        ><a ngbDropdownItem [routerLink]="['/apps/todo']"
          ><span [data-feather]="'check-square'" [class]="'mr-50'"></span> Task</a
        ><a ngbDropdownItem [routerLink]="['/apps/chat']"
          ><span [data-feather]="'message-square'" [class]="'mr-50'"></span> Chats</a
        >
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem [routerLink]="['/pages/account-settings']"
          ><span [data-feather]="'settings'" [class]="'mr-50'"></span> Settings</a
        ><a ngbDropdownItem [routerLink]="['/pages/pricing']"
          ><span [data-feather]="'credit-card'" [class]="'mr-50'"></span> Pricing</a
        ><a ngbDropdownItem [routerLink]="['/pages/faq']"
          ><span [data-feather]="'help-circle'" [class]="'mr-50'"></span> FAQ</a
        ><a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a>
      </div> -->
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
<ng-template #menu>
<div class="horizontal-menu-wrapper">
  <!-- Vertical overlay menu: Visible below large screen only  -->
  <core-sidebar
    name="menu"
    [collapsed]="coreConfig.layout.menu.collapsed"
    collapsibleSidebar="bs-gt-xl"
    *ngIf="!coreConfig.layout.menu.hidden"
    class="main-menu menu-fixed menu-light menu-accordio menu-shadow d-xl-none"
    overlayClass="sidenav-overlay"
    [ngClass]="[coreConfig.layout.menu.collapsed === true ? '' : 'expanded']"
  >
    <app-menu menuType="vertical-menu" class="vertical-menu"></app-menu>
  </core-sidebar>
</div>
</ng-template>