import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amount'
})
export class AmountPipe implements PipeTransform {

  transform(value: any): any {
    let amount = Math.abs(Number(value))>=1000
    ?Math.abs(Number(value)).toLocaleString("en-US")
    : Math.abs(Number(value))
    // Math.abs(Number(value)) >= 1.0e+12
    // ? this.setFrafment(Math.abs(Number(value)) / 1.0e+12) + "T"
    // : Math.abs(Number(value)) >= 1.0e+9
    //   ? this.setFrafment(Math.abs(Number(value)) / 1.0e+9) + "B"
    //   : Math.abs(Number(value)) >= 1.0e+6
    //     ? this.setFrafment(Math.abs(Number(value)) / 1.0e+6) + "M"
    //     : Math.abs(Number(value))>=1000
    //     ?Math.abs(Number(value)).toLocaleString("en-US")
    //     : Math.abs(Number(value))
    return amount;
  }

  setFrafment(num) {
    num = num.toString();
    num = num.slice(0, (num.indexOf(".")) + 2 + 1);
    return Number(num);
  }

}
