<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- Content-Header Component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section id="bar-and-hori-bar-charts">
      <div class="row">
        <!-- Bar Chart Start -->
        <div class="col-xl-6 col-12">
          <div class="card">
            <div
              class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column"
            >
              <div class="header-left">
                <h4 class="card-title">Latest Statistics</h4>
              </div>
              <div class="header-right d-flex align-items-center mt-sm-0 mt-1">
                <i class="font-medium-2" data-feather="calendar"></i>
                <ng2-flatpickr [config]="DateRangeOptions" name="DateRange"></ng2-flatpickr>
              </div>
            </div>
            <div class="card-body">
              <div id="chartjs-bar-chart">
                <canvas
                  baseChart
                  #barChartRef
                  height="400"
                  [datasets]="barChart.datasets"
                  [labels]="barChart.labels"
                  [options]="barChart.options"
                  [legend]="barChart.legend"
                  [chartType]="barChart.chartType"
                >
                </canvas>
              </div>
            </div>
          </div>
        </div>
        <!-- Horizontal Bar Chart Start -->
        <div class="col-xl-6 col-12">
          <div class="card">
            <div
              class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column"
            >
              <div class="header-left">
                <p class="card-subtitle text-muted mb-25">Balance</p>
                <h4 class="card-title">$74,123</h4>
              </div>
              <div class="header-right d-flex align-items-center mt-sm-0 mt-1">
                <i class="font-medium-2" data-feather="calendar"></i>
                <ng2-flatpickr [config]="DateRangeOptions" name="DateRange"></ng2-flatpickr>
              </div>
            </div>
            <div class="card-body">
              <canvas
                baseChart
                class="horizontal-bar-chart-ex chartjs"
                height="400"
                [datasets]="horiBarChart.datasets"
                [labels]="horiBarChart.labels"
                [options]="horiBarChart.options"
                [legend]="horiBarChart.legend"
                [chartType]="horiBarChart.chartType"
              ></canvas>
            </div>
          </div>
        </div>
        <!--/ Horizontal Bar Chart End -->
      </div>
    </section>

    <section id="line-chart">
      <!-- Line Chart Starts-->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div>
                <h4 class="card-title">Statistics</h4>
                <span class="card-subtitle text-muted">Commercial networks and enterprises</span>
              </div>
            </div>
            <div class="card-body">
              <canvas
                class="line-chart-ex chartjs"
                baseChart
                height="400"
                [datasets]="lineChart.datasets"
                [chartType]="lineChart.chartType"
                [options]="lineChart.options"
                [labels]="lineChart.labels"
                [plugins]="plugins"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
      <!--/ Line Chart Ends-->
    </section>

    <section id="radar-and-polar-charts">
      <div class="row">
        <!-- Radar Chart Starts-->
        <div class="col-lg-6 col-12">
          <div class="card">
            <div class="card-header d-flex flex-row justify-content-between align-items-center">
              <h4 class="card-title">Radar Chart</h4>
              <div class="d-flex align-items-center flex-wrap">
                <div id="tooltip" class="tooltip-placeholder"></div>
              </div>
            </div>
            <div class="card-body">
              <canvas
                class="radar-chart-ex chartjs"
                height="355"
                baseChart
                [datasets]="radarChart.datasets"
                [options]="radarChart.options"
                [labels]="radarChart.labels"
                [chartType]="radarChart.chartType"
                [plugins]="plugins"
              ></canvas>
            </div>
          </div>
        </div>
        <!--/ Radar Chart Ends-->

        <!-- Polar Area Chart Starts -->
        <div class="col-lg-6 col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Average Skills</h4>
              <div ngbDropdown>
                <button
                  class="btn p-0"
                  ngbDropdownToggle
                  type="button"
                  id="dropdownItem4"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i data-feather="more-vertical" class="font-medium-3 cursor-pointer"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="heat-chart-dd">
                  <a ngbDropdownItem href="javascript:void(0);">Last 28 Days</a>
                  <a ngbDropdownItem href="javascript:void(0);">Last Month</a>
                  <a ngbDropdownItem href="javascript:void(0);">Last Year</a>
                </div>
              </div>
            </div>
            <div class="card-body">
              <canvas
                baseChart
                class="polar-area-chart-ex chartjs"
                height="350"
                [datasets]="polarAreaChart.datasets"
                [labels]="polarAreaChart.labels"
                [options]="polarAreaChart.options"
                [chartType]="polarAreaChart.chartType"
              ></canvas>
            </div>
          </div>
        </div>
        <!--/ Polar Area Chart Ends-->
      </div>
    </section>

    <section id="bubble-chart">
      <!-- Bubble Chart Starts -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Bubble Chart</h4>
              <div class="d-flex align-items-center flex-wrap">
                <h5 class="font-weight-bolder mb-0 mr-1">$ 100,000</h5>
                <span class="badge badge-light-secondary">
                  <i class="text-danger font-small-3" data-feather="arrow-down"></i>
                  <span class="align-middle">20%</span>
                </span>
              </div>
            </div>
            <div class="card-body">
              <canvas
                class="bubble-chart-ex chartjs"
                height="500"
                baseChart
                [datasets]="bubbleChart.datasets"
                [options]="bubbleChart.options"
                [chartType]="bubbleChart.chartType"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
      <!--/ Bubble Chart Ends -->
    </section>

    <section id="Donut-and-scatter-charts">
      <div class="row">
        <!-- Donut Chart Starts -->
        <div class="col-lg-4 col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Sessions By Device</h4>
            </div>
            <div class="card-body">
              <canvas
                class="doughnut-chart-ex chartjs mx-auto"
                baseChart
                [datasets]="doughnutChart.datasets"
                [options]="doughnutChart.options"
                [chartType]="doughnutChart.chartType"
              >
              </canvas>
              <div class="d-flex justify-content-between mt-3 mb-1">
                <div class="d-flex align-items-center">
                  <i data-feather="monitor" class="font-medium-2 text-primary"></i>
                  <span class="font-weight-bold ml-75 mr-25">Desktop</span>
                  <span>- 80%</span>
                </div>
                <div>
                  <span>2%</span>
                  <i data-feather="arrow-up" class="text-success"></i>
                </div>
              </div>
              <div class="d-flex justify-content-between mb-1">
                <div class="d-flex align-items-center">
                  <i data-feather="tablet" class="font-medium-2 text-warning"></i>
                  <span class="font-weight-bold ml-75 mr-25">Mobile</span>
                  <span>- 10%</span>
                </div>
                <div>
                  <span>8%</span>
                  <i data-feather="arrow-up" class="text-success"></i>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <i data-feather="tablet" class="font-medium-2 text-success"></i>
                  <span class="font-weight-bold ml-75 mr-25">Tablet</span>
                  <span>- 10%</span>
                </div>
                <div>
                  <span>-5%</span>
                  <i data-feather="arrow-down" class="text-danger"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/ Donut Chart Starts -->

        <!-- Scatter Chart Starts  -->
        <div class="col-lg-8 col-12">
          <div class="card">
            <div
              class="card-header d-flex justify-content-between align-items-baseline align-items-sm-center flex-sm-row flex-column"
            >
              <div>
                <h4 class="card-title mb-25">New Product Data</h4>
              </div>
              <div
                class="btn-group btn-group-toggle mt-md-0 mt-1"
                ngbRadioGroup
                name="radioBasic"
                [(ngModel)]="radioModel"
              >
                <label class="btn btn-outline-primary" ngbButtonLabel rippleEffect>
                  <input type="radio" name="radio_options" id="radio_option1" [value]="1" ngbButton />
                  <span>Daily</span>
                </label>
                <label class="btn btn-outline-primary" ngbButtonLabel rippleEffect>
                  <input type="radio" name="radio_options" id="radio_option2" ngbButton [value]="2" />
                  <span>Monthly</span>
                </label>
                <label class="btn btn-outline-primary" ngbButtonLabel rippleEffect>
                  <input type="radio" name="radio_options" id="radio_option3" ngbButton [value]="3" />
                  <span>Yearly</span>
                </label>
              </div>
            </div>
            <div class="card-body">
              <canvas
                class="scatter-chart-ex chartjs"
                baseChart
                height="415"
                [datasets]="scatterChart.datasets"
                [options]="scatterChart.options"
                [chartType]="scatterChart.chartType"
                [plugins]="plugins"
              ></canvas>
            </div>
          </div>
        </div>
        <!--/ Scatter Chart Ends  -->
      </div>
    </section>

    <section id="area-chart">
      <!-- Area Chart Starts -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between align-items-baseline flex-sm-row flex-column">
              <div>
                <h4 class="card-title">Data Science</h4>
              </div>
              <div class="header-right d-flex align-items-center mt-sm-0 mt-1">
                <i class="font-medium-2" data-feather="calendar"></i>
                <ng2-flatpickr [config]="DateRangeOptions" name="DateRange"></ng2-flatpickr>
              </div>
            </div>
            <div class="card-body">
              <canvas
                class="line-area-chart-ex chartjs"
                baseChart
                height="450"
                [datasets]="lineAreaChart.datasets"
                [labels]="lineAreaChart.labels"
                [options]="lineAreaChart.options"
                [chartType]="lineAreaChart.chartType"
                [plugins]="plugins"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
      <!--/ Area Chart Ends -->
    </section>
  </div>
</div>
