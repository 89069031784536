import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { CoreMenuItem } from '@core/types/core-menu';
import { GeneralService } from 'app/auth/service';

@Component({
  selector: '[core-menu-horizontal-item]',
  templateUrl: './item.component.html'
})
export class CoreMenuHorizontalItemComponent {
  @Input()
  item: any;

  constructor(private _general:GeneralService){

  }

  action(key){
    this._general.action.next(key)
  }


  
}
