import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule,TranslateLoader } from '@ngx-translate/core';
// import { TranslateHttpLoader } from "@ngx-translate/http-loader";
// import { HttpClient } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomBreakPointsProvider } from 'app/layout/custom-breakpoints';
import { GeneralService } from 'app/auth/service/general.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CoreSidebarModule } from '@core/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsAndMapsModule } from 'app/main/charts-and-maps/charts-and-maps.module';
import { ChartjsModule } from 'app/main/charts-and-maps/charts/chartjs/chartjs.module';
import { LogoutComponent } from './components/logout/logout.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component'; 
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TranslatePipe } from './pipe/translate.pipe';
import { AmountPipe } from './pipe/amount.pipe';

// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }
let language=localStorage.getItem("Language")
@NgModule({
  declarations: [
    LogoutComponent,
    ConfirmationModalComponent,
    TranslatePipe,
    AmountPipe
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    NgbModule,
    NgxDatatableModule,
    FlexLayoutModule,
    CoreSidebarModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsAndMapsModule,
    ChartjsModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient]
    //   }
    // }),
    CKEditorModule 
  ],
  exports: [
    TranslateModule,
    NgSelectModule,
    NgbModule,
    NgxDatatableModule,
    FlexLayoutModule,
    CoreSidebarModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsAndMapsModule,
    ChartjsModule,
    LogoutComponent,
    CKEditorModule ,
    AngularEditorModule,
    AmountPipe
  ],
  providers: [CustomBreakPointsProvider]
})
export class SharedModule { }
